import Container_AppleAreaComponent from '../components/Container/viewer/skinComps/AppleArea/AppleArea.skin';


const Container_AppleArea = {
  component: Container_AppleAreaComponent
};


export const components = {
  ['Container_AppleArea']: Container_AppleArea
};

